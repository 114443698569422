import CmsGeneralConfigFrame from "@/components/cms/cmsGeneralConfigFrame/cmsGeneralConfigFrame";
import PbContentImage from "@/components/content/pbContentImage/pbContentImage";
import PbContentRichText from "@/components/content/pbContentRichText/pbContentRichText";
import PbGenericModal from "@/components/genericModal/pbGenericModal";
import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import AnimationWrapper from "@/components/util/animationWrapper/animationWrapper";
import useCesStrRichText from "@/hooks/useCesStrRichText";
import useConfirmModal from "@/hooks/useConfirmModal";
import useImageWithMarkersSettings from "@/hooks/useImageWithMarkersSettings";
import useScopedClassName from "@/hooks/useScopedClassName";
import useStrIcon from "@/hooks/useStrIcon";
import useWindowSize from "@/hooks/useWindowSize";
import {
  ceSettingById,
  getNestedCeSettingsData,
  valueFromStoreSetting,
} from "@/services/ceSettings/ceSettingsService";
import {
  removeFromArrayAction,
  updateAttributeAction,
} from "@/store/slices/cmsEdit/cmsEditSlice";
import { cmsAddNestedContentElementThunk } from "@/store/slices/cmsEdit/cmsPageThunks";
import { showNestedConfigModal } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { ContentElementImageWithMarkersStoreSetting } from "@/types/ceSettings/ceSettings";
import {
  CEImageWithMarkers,
  CEImageWithMarkersItem,
} from "@/types/content-elements";
import { createToast } from "@/utils/utilComponents";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Col, Row } from "react-bootstrap";
import Draggable from "react-draggable";
import { Resizable, ResizeCallbackData } from "react-resizable";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import ImageWithMarkersContentElementStyleGlobal from "./imageWithMarkersContentElementStyleGlobal";
import ImageWithMarkersContentElementStyleScoped from "./imageWithMarkersContentElementStyleScoped";

export interface ImageWithMarkersContentElementProps {
  content: CEImageWithMarkers;
  position: number;
  ceSettings?: ContentElementImageWithMarkersStoreSetting;
  isLastElement: boolean;
  isMobile: boolean;
  isFirstElement: boolean;
}

export const IMAGE_WITH_MARKERS_CE_NAME = "imageWithMarkersCE";

export default React.memo(function ImageWithMarkersContentElement(
  props: ImageWithMarkersContentElementProps
) {
  const { t: tCms } = useTranslation("cms");
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const nestedCEModal = useAppSelector(
    (state) => state.cmsGeneral.nestedContentModal
  );
  const IMAGE_WITH_MARKERS_SCOPED_CLASS = useScopedClassName(
    IMAGE_WITH_MARKERS_CE_NAME,
    props.content,
    props.position
  );
  const { getIcon } = useStrIcon();
  const dispatch = useAppDispatch();
  const [positionEditMode, setPositionEditMode] = useState<boolean>(true);
  const [textBoxDimensions, setTextBoxDimensions] = useState<{
    [id: string]: { width: number; height: number; invisible: boolean };
  }>({});
  const [generalScalingState, setGeneralScalingState] = useState<number>(1);
  const [previousNestedCEModalOpenState, setPreviousNestedCEModalOpenState] =
    useState<boolean>(false);
  const { width } = useWindowSize();
  const contentRef = useRef<HTMLDivElement>(null);
  const textItemsRef = useRef<any>([]);
  const markersRef = useRef<any>([]);
  const imageRef = useRef<HTMLImageElement>(null);
  const [allTextBoxesInvisible, setAllTextBoxesInvisible] = useState(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<
    CEImageWithMarkersItem | undefined
  >();

  const { overlayItemBackgroundColor, overlayItemMarkerColor } =
    useImageWithMarkersSettings(props.ceSettings);

  const { richTextClassName } = useCesStrRichText(
    props.ceSettings?.richtext!,
    props.ceSettings?.richtext
  );

  const nestedElementAttributes = useAppSelector((state) => {
    return state.cmsEdit.data?.availableElements?.find(
      (element: any) => props.content.__component === element.uid
    )?.nestedElement;
  });

  const markersIndentIntoImagePixel = 30;
  const maxImageWidth = props.content.cfgImageWidth || 800;
  const maxImageHeight = props.content.cfgImageHeight || 600;

  const cfgShowMarkersOnAllSizes = props.content.cfgShowMarkersOnAllSizes
    ? true
    : false;

  const [selectedMarker, setSelectedMarker] = useState<number>(-1);

  const { showConfirmModal } = useConfirmModal(
    () => {
      dispatch(
        removeFromArrayAction({
          attributePath: `draftPage.content[${props.position}].content`,
          position: selectedMarker,
        })
      );
    },
    () => {
      setSelectedMarker(-1);
    }
  );

  const { showConfirmModal: showResetPositionConfirmModal } = useConfirmModal(
    () => {
      if (props.content.content) {
        props.content.content.forEach((element: any, index: number) => {
          dispatch(
            updateAttributeAction({
              attributePath: `draftPage.content[${props.position}].content[${index}].markerPosition`,
              value: { x: 1, y: 1 },
            })
          );
          dispatch(
            updateAttributeAction({
              attributePath: `draftPage.content[${props.position}].content[${index}].position`,
              value: { x: 1, y: 1 },
            })
          );
        });
      }
    }
  );

  const handleOnMarkerDelete = (index: number) => {
    setSelectedMarker(index);
    showConfirmModal({
      title: tCms("nestedContent-deleteImgWithMarkersMarker"),
      content: tCms("nestedContent-deleteImgWithMarkersMarkerLong"),
      acceptBtnText: tCms("delete"),
      denyBtnText: tCms("cancel"),
      xIsDeny: true,
      icon: "circle-exclamation-light",
    });
  };

  const arrLength = props.content.content.length;
  if (textItemsRef.current.length !== arrLength) {
    textItemsRef.current = Array(arrLength)
      .fill(undefined)
      .map((_, i) => textItemsRef.current[i] || createRef());
  }
  if (markersRef.current.length !== arrLength) {
    markersRef.current = Array(arrLength)
      .fill(undefined)
      .map((_, i) => markersRef.current[i] || createRef());
  }

  const toggleDialog = (element?: CEImageWithMarkersItem) => {
    setDialogOpen((prevState: boolean) => !prevState);
    if (element) {
      setDialogContent(element);
    }
  };

  const changeTextBoxDimensionState = useCallback(
    (element: any, value: any) => {
      if (element.id) {
        setTextBoxDimensions((prevState: any) => {
          return {
            ...prevState,
            [element.id]: { ...prevState[element.id], ...value },
          };
        });
      } else if (element.__new_id) {
        setTextBoxDimensions((prevState: any) => {
          return {
            ...prevState,
            [element.__new_id]: { ...prevState[element.__new_id], ...value },
          };
        });
      }
    },
    []
  );

  // updating local state with redux information on close nestedCEModal
  useEffect(() => {
    if (nestedCEModal.position === props.position) {
      setPreviousNestedCEModalOpenState(true);
    }
    if (nestedCEModal?.position && previousNestedCEModalOpenState) {
      props.content.content.forEach((element: any, index: number) => {
        changeTextBoxDimensionState(element, {
          width: element.width,
          height: element.height,
        });
      });
      setPreviousNestedCEModalOpenState(false);
    }
  }, [
    nestedCEModal,
    previousNestedCEModalOpenState,
    changeTextBoxDimensionState,
    props.content.content,
    props.position,
  ]);

  const getTextBoxDimensionValues = useCallback(
    (element: any) => {
      if (element.id) {
        return textBoxDimensions[element.id] || {};
      } else if (element.__new_id) {
        return textBoxDimensions[element.__new_id] || {};
      }
    },
    [textBoxDimensions]
  );

  // onRepositionStop triggers when the user lets go of a textbox after dragging it
  const onRepositionStop = (
    e: any,
    position: { x: number; y: number },
    index: number,
    element: any
  ) => {
    const { x, y } = position;
    dispatch(
      updateAttributeAction({
        attributePath: `draftPage.content[${props.position}].content[${index}].position`,
        value: {
          x: x,
          y: y,
        },
      })
    );
  };

  // onRepositionStop triggers when the user lets go of the pulsing marker after dragging it
  const onMarkerRepositionStop = (
    e: any,
    position: { x: number; y: number },
    index: number,
    element: any
  ) => {
    const { x, y } = position;

    const imageWidthAfterIndent =
      (imageRef?.current?.offsetWidth ?? 0) / 2 - markersIndentIntoImagePixel;
    const imageHeightAfterIndent =
      (imageRef?.current?.offsetHeight ?? 0) / 2 - markersIndentIntoImagePixel;

    const isInsideOfImageRight = imageWidthAfterIndent > x;
    const isInsideOfImageLeft = -Math.abs(imageWidthAfterIndent) < x;

    const isInsideOfImageBottom = imageHeightAfterIndent > y;
    const isInsideOfImageTop = -Math.abs(imageHeightAfterIndent) < y;

    let newX = x;
    let newY = y;

    if (!isInsideOfImageRight) {
      newX = imageWidthAfterIndent;
    }
    if (!isInsideOfImageLeft) {
      newX = -Math.abs(imageWidthAfterIndent);
    }
    if (!isInsideOfImageBottom) {
      newY = imageHeightAfterIndent;
    }
    if (!isInsideOfImageTop) {
      newY = -Math.abs(imageHeightAfterIndent);
    }

    dispatch(
      updateAttributeAction({
        attributePath: `draftPage.content[${props.position}].content[${index}].markerPosition`,
        value: {
          x: newX,
          y: newY,
        },
      })
    );
  };

  // resize of textboxes
  // this only handles the smooth resizing process. not the saving process
  const onResize = (
    event: React.SyntheticEvent,
    { size }: ResizeCallbackData,
    index: number,
    element: any
  ) => {
    changeTextBoxDimensionState(element, {
      width: size?.width,
      height: size?.height,
    });
  };

  // saving of new size of textboxes
  // this handles the redux update after resizing
  // and the correction if the textbox is bigger than allowed (oustide of the parent)
  const onResizeStop = (
    event: React.SyntheticEvent,
    { size }: ResizeCallbackData,
    index: number,
    element: any
  ) => {
    const textBoxValues = getTextBoxDimensionValues(element);
    // check if element with new width is inside the parent
    if (
      textBoxValues?.width + element.position.x <
      (contentRef?.current?.offsetWidth ?? 0) / 2
    ) {
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.position}].content[${index}].width`,
          value: size.width,
        })
      );
      // if width is dragged larger than the box container, change value to max height possible
    } else {
      createToast({
        type: "warning",
        msg: tCms("imgWithMarkers-maximumWidthReached"),
      });
      changeTextBoxDimensionState(element, {
        width: (contentRef?.current?.offsetWidth ?? 0) / 2 - element.position.x,
      });
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.position}].content[${index}].width`,
          value:
            (contentRef?.current?.offsetWidth ?? 0) / 2 - element.position.x,
        })
      );
    }
    // check if element with new height is inside the parent
    if (
      textBoxValues?.height + element.position.y <
      (contentRef?.current?.offsetHeight ?? 0) / 2
    ) {
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.position}].content[${index}].height`,
          value: size.height,
        })
      );
      // if height is dragged larger than the box container change value to max height possible
    } else {
      createToast({
        type: "warning",
        msg: tCms("imgWithMarkers-maximumHeightReached"),
      });
      changeTextBoxDimensionState(element, {
        height:
          (contentRef?.current?.offsetHeight ?? 0) / 2 - element.position.y,
      });
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.position}].content[${index}].height`,
          value:
            (contentRef?.current?.offsetHeight ?? 0) / 2 - element.position.y,
        })
      );
    }
  };

  useEffect(() => {
    if (contentRef.current && imageRef.current) {
      const halfContainerWidth = contentRef.current.offsetWidth / 2;
      const halfImageWidth = imageRef.current.offsetWidth / 2;
      const halfImageHeight = imageRef.current.offsetHeight / 2;
      const widthScaling = (halfImageWidth * 2) / maxImageWidth;
      const heightScaling = (halfImageHeight * 2) / maxImageHeight;

      const calculateNewMarkerPosition = (position: number, isX: boolean) => {
        const imageWidthOrHeight = isX ? halfImageWidth : halfImageHeight;

        // anywhere fully visible inside the image
        let newPosition = generalScaling * position;
        // if the marker position value is higher than image - indent (for negative lower)
        // use the image - indet value instead
        if (
          generalScaling * position > 0 &&
          Math.abs(imageWidthOrHeight - markersIndentIntoImagePixel) <
            generalScaling * position
        ) {
          newPosition = Math.abs(
            imageWidthOrHeight - markersIndentIntoImagePixel
          );
        } else if (
          generalScaling * position <= 0 &&
          -Math.abs(imageWidthOrHeight - markersIndentIntoImagePixel) >
            generalScaling * position
        ) {
          newPosition = -Math.abs(
            imageWidthOrHeight - markersIndentIntoImagePixel
          );
        }
        return newPosition;
      };

      const generalScaling =
        widthScaling >= heightScaling ? widthScaling : heightScaling;

      if (generalScalingState !== generalScaling) {
        setGeneralScalingState(generalScaling);
      }

      props.content.content.forEach((element: any, index: number) => {
        if (
          element.position?.x !== 0 &&
          element.position?.y !== 0 &&
          element.markerPosition?.y !== 0 &&
          element.markerPosition?.x !== 0 &&
          !editView
        ) {
          const contentWidth =
            textBoxDimensions[element.id]?.width ||
            textBoxDimensions[element.__new_id]?.width ||
            element.width;

          let goInvisible = false;
          let transform = false;
          let newPositionX = element.position?.x;
          let newPositionY = element.position?.y;
          let newMarkerPositionX = element.markerPosition?.x;
          let newMarkerPositionY = element.markerPosition?.y;

          // left corner is on the right side
          if (element.position.x >= 0) {
            // true if the element touches the right parent border
            if (
              halfContainerWidth <
              element.position.x * generalScaling + contentWidth
            ) {
              const leftSideCoordinateOfTextbox =
                halfContainerWidth - contentWidth;
              // case 1: element is inside the image and touching the border of parent (small screen)
              // true if left side of the element is more than the markersIndentIntoImagePixel inside the image
              if (
                leftSideCoordinateOfTextbox - halfImageWidth <
                -markersIndentIntoImagePixel
              ) {
                goInvisible = true;
                transform = true;

                newMarkerPositionX = calculateNewMarkerPosition(
                  element.markerPosition.x,
                  true
                );
                newMarkerPositionY = calculateNewMarkerPosition(
                  element.markerPosition.y,
                  false
                );
              } else {
                // case 2: element is outside of image and touching border of parent
                goInvisible = false;
                transform = true;
                newPositionX = halfContainerWidth - contentWidth;
                newPositionY = element.position.y;
                newMarkerPositionX = element.markerPosition.x;
                newMarkerPositionY = element.markerPosition.y;
              }
            } else {
              // case 3: element is in or outside of the image and NOT touching the parents border
              goInvisible = false;
              transform = true;
              newPositionX = generalScaling * element.position?.x;
              newPositionY = generalScaling * element.position?.y;
              newMarkerPositionX = generalScaling * element.markerPosition?.x;
              newMarkerPositionY = generalScaling * element.markerPosition?.y;
            }
            // left corner is on the left side
          } else if (element.position.x < 0) {
            // image has 0,7 (70%) in size => rest is 0,3 (30%)
            const restOfScale = -1 + generalScaling;

            // if an element starts on the left an touches the right border first
            if (
              halfContainerWidth +
                element.position.x * generalScaling +
                contentWidth >
              halfContainerWidth * 2
            ) {
              goInvisible = true;
              transform = true;
              newMarkerPositionX = calculateNewMarkerPosition(
                element.markerPosition.x,
                true
              );
              newMarkerPositionY = calculateNewMarkerPosition(
                element.markerPosition.y,
                false
              );
            } else if (
              halfContainerWidth <
              -element.position.x * generalScaling +
                -(contentWidth * restOfScale)
            ) {
              const rightSideCoordinate = halfContainerWidth - contentWidth;
              // case 1: element is inside the image and touching the border of parent (small screen)
              // true if right side of the element is more than the markersIndentIntoImagePixel inside the image
              if (
                rightSideCoordinate - halfImageWidth <
                -markersIndentIntoImagePixel
              ) {
                goInvisible = true;
                transform = true;
                newMarkerPositionX = calculateNewMarkerPosition(
                  element.markerPosition.x,
                  true
                );
                newMarkerPositionY = calculateNewMarkerPosition(
                  element.markerPosition.y,
                  false
                );
              } else {
                // case 2: element is outside of image and touching left border of parent
                goInvisible = false;
                transform = true;
                newPositionX = -halfContainerWidth;
                newPositionY = element.position.y;
                newMarkerPositionX = element.markerPosition.x;
                newMarkerPositionY = element.markerPosition.y;
              }
            } else {
              // case 3: element is in or outside of the image and NOT touching the parents border
              goInvisible = false;
              transform = true;
              newPositionX =
                generalScaling * element.position?.x +
                restOfScale * contentWidth;
              newPositionY = generalScaling * element.position?.y;
              newMarkerPositionX = generalScaling * element.markerPosition?.x;
              newMarkerPositionY = generalScaling * element.markerPosition?.y;
            }
          }

          const textBoxDimensionValues = getTextBoxDimensionValues(element);

          if (goInvisible && !textBoxDimensionValues?.invisible) {
            changeTextBoxDimensionState(element, {
              invisible: true,
              markerPositionX: newMarkerPositionX,
              markerPositionY: newMarkerPositionY,
            });
          }
          if (!goInvisible && textBoxDimensionValues?.invisible) {
            changeTextBoxDimensionState(element, {
              invisible: false,
              x: newPositionX,
              y: newPositionY,
              markerPositionX: newMarkerPositionX,
              markerPositionY: newMarkerPositionY,
            });
          }
          // update the positions for textboxes and markers if the image is not 100% size anymore
          if (transform) {
            textItemsRef.current[
              index
            ].current.style.transform = `translate(${newPositionX}px, ${newPositionY}px)`;
            markersRef.current[
              index
            ].current.style.transform = `translate(${newMarkerPositionX}px, ${newMarkerPositionY}px)`;
          }
        }
      });

      // Check if any textbox is invisible
      let isAnyInvisible = false;
      if (Object.keys(textBoxDimensions).length > 0) {
        for (let textBoxId of Object.keys(textBoxDimensions)) {
          if (textBoxDimensions[textBoxId].invisible) {
            isAnyInvisible = true;
            break;
          }
        }
      }
      // If any textbox is invisible, all textboxes should be hidden
      if (isAnyInvisible !== allTextBoxesInvisible) {
        setAllTextBoxesInvisible(isAnyInvisible);
      }
    }
  }, [
    width,
    editView,
    changeTextBoxDimensionState,
    getTextBoxDimensionValues,
    props.content.content,
    textBoxDimensions,
    generalScalingState,
    maxImageWidth,
    maxImageHeight,
    markersIndentIntoImagePixel,
    allTextBoxesInvisible,
  ]);

  return (
    <>
      <ContentElementWrapper
        name={IMAGE_WITH_MARKERS_CE_NAME}
        scopedClassName={IMAGE_WITH_MARKERS_SCOPED_CLASS}
        content={props.content}
        ceSettings={props.ceSettings}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <div
          className={clsx(
            IMAGE_WITH_MARKERS_CE_NAME,
            IMAGE_WITH_MARKERS_SCOPED_CLASS
          )}
        >
          <Row
            id={
              props.content.cfgAnchorName
                ? `anchor-${props.content.cfgAnchorName}`
                : ""
            }
            className="g-0"
          >
            <Col xs={12}>
              <div className="content" ref={contentRef}>
                <div className="disable-overflow">
                  <div className="image">
                    <figure>
                      {props.content.cfgImg ? (
                        <PbContentImage
                          img={props.content.cfgImg}
                          cmsPosition={props.position}
                          cmsFieldImage={"img"}
                          maxWidth={`${props.content.cfgImageWidth}px`}
                          maxHeight={`${props.content.cfgImageHeight}px`}
                          height={"auto"}
                          width="100%"
                          isMobile={props.isMobile}
                          disableEditView
                          className="mx-auto"
                          imageRef={imageRef}
                        />
                      ) : (
                        <div style={{ height: "300px" }}></div>
                      )}
                    </figure>
                  </div>
                  {props.content.content.map(
                    (element: CEImageWithMarkersItem, index: number) => {
                      const contentHeight =
                        getTextBoxDimensionValues(element)?.height ||
                        element.height;
                      const contentWidth =
                        getTextBoxDimensionValues(element)?.width ||
                        element.width;
                      const isInvisible =
                        allTextBoxesInvisible ||
                        textBoxDimensions[element.id]?.invisible ||
                        textBoxDimensions[element.__new_id]?.invisible;

                      const { id: iconId } = getIcon(
                        element.cfgStrMarkerStyle,
                        props.ceSettings?.marker
                      );
                      const backgroundColor = overlayItemBackgroundColor(
                        element.cfgStrBackgroundColor
                      );
                      const markerColor = overlayItemMarkerColor(
                        element.cfgStrMarkerColor
                      );
                      return (
                        <React.Fragment key={"draggable-" + index}>
                          <Draggable
                            position={element.markerPosition}
                            onStart={() => {
                              if (positionEditMode && editView) {
                                const scrollbarWidth =
                                  window.innerWidth -
                                  document.documentElement.clientWidth;
                                document.body.style.paddingRight = `${scrollbarWidth}px`;
                                document.body.style.overflow = "hidden";
                              } else {
                                return false;
                              }
                            }}
                            onStop={(e, ui) => {
                              if (positionEditMode && editView) {
                                document.body.style.removeProperty("overflow");
                                document.body.style.paddingRight = "0px";
                                onMarkerRepositionStop(e, ui, index, element);
                              } else {
                                return false;
                              }
                            }}
                            bounds="parent"
                          >
                            <div
                              className={clsx(
                                "marker-position-wrapper-free",
                                editView && "edit-mode",
                                (isInvisible || cfgShowMarkersOnAllSizes) &&
                                  "visible"
                              )}
                              ref={markersRef.current[index]}
                            >
                              <AnimationWrapper
                                animationIn={element.cfgAnimationIn}
                                animationOut={element.cfgAnimationOut}
                              >
                                <button
                                  className="marker-button"
                                  aria-label={tCms(
                                    "imgwithmarkers-open-text-overlay"
                                  )}
                                  tabIndex={isInvisible && !editView ? 0 : -1}
                                  onClick={() => {
                                    return (isInvisible ||
                                      cfgShowMarkersOnAllSizes) &&
                                      !editView &&
                                      element.text
                                      ? toggleDialog(element)
                                      : null;
                                  }}
                                  onTouchEnd={() => {
                                    return (isInvisible ||
                                      cfgShowMarkersOnAllSizes) &&
                                      !editView
                                      ? toggleDialog(element)
                                      : null;
                                  }}
                                >
                                  <div className="marker-container">
                                    <div
                                      className="marker"
                                      style={{
                                        backgroundColor: markerColor || "blue",
                                        boxShadow: `0 0 1px 2px ${markerColor}`,
                                      }}
                                    >
                                      {iconId ? (
                                        <div
                                          className={`str-icon-${iconId}`}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            backgroundSize: "cover",
                                          }}
                                        />
                                      ) : (
                                        <span>{index + 1}</span>
                                      )}
                                      <div
                                        className="marker-pulse"
                                        style={{
                                          backgroundColor:
                                            markerColor ||
                                            (iconId ? "transparent" : "blue"),
                                        }}
                                      ></div>
                                    </div>
                                    <div
                                      className="marker-pulse"
                                      style={{
                                        backgroundColor:
                                          markerColor ||
                                          (iconId ? "transparent" : "blue"),
                                      }}
                                    ></div>
                                  </div>
                                </button>
                              </AnimationWrapper>
                            </div>
                          </Draggable>
                          <Draggable
                            position={element.position}
                            onStart={() => {
                              if (positionEditMode && editView) {
                                const scrollbarWidth =
                                  window.innerWidth -
                                  document.documentElement.clientWidth;
                                document.body.style.paddingRight = `${scrollbarWidth}px`;
                                document.body.style.overflow = "hidden";
                              } else {
                                return false;
                              }
                            }}
                            onStop={(e, ui) => {
                              if (positionEditMode && editView) {
                                document.body.style.removeProperty("overflow");
                                document.body.style.paddingRight = "0px";
                                onRepositionStop(e, ui, index, element);
                              } else {
                                return false;
                              }
                            }}
                            bounds="parent"
                            cancel=".pb-richtext, .react-resizable-handle"
                          >
                            <Resizable
                              height={contentHeight}
                              width={contentWidth}
                              onResize={(
                                e: any,
                                values: ResizeCallbackData
                              ) => {
                                if (positionEditMode && editView) {
                                  onResize(e, values, index, element);
                                }
                              }}
                              onResizeStop={(e, values: ResizeCallbackData) => {
                                if (positionEditMode && editView) {
                                  onResizeStop(e, values, index, element);
                                }
                              }}
                            >
                              <div
                                ref={textItemsRef.current[index]}
                                className={clsx(
                                  positionEditMode && editView && "edit-mode",
                                  !editView &&
                                    (isInvisible || cfgShowMarkersOnAllSizes) &&
                                    "d-none",
                                  "text-box"
                                )}
                                style={{
                                  width: contentWidth + "px",
                                  height: contentHeight + "px",
                                }}
                              >
                                <div className="drag-zone">
                                  <span>{tCms("imgWithMarkers-drag-me")}</span>
                                </div>
                                <AnimationWrapper
                                  animationIn={element.cfgAnimationIn}
                                  animationOut={element.cfgAnimationOut}
                                >
                                  <div
                                    className={clsx(
                                      isInvisible && "invisible",
                                      "text-content"
                                    )}
                                    style={{
                                      backgroundColor:
                                        backgroundColor || "white",
                                    }}
                                  >
                                    <CmsGeneralConfigFrame
                                      frameOnHover={true}
                                      hideAddCEButton={true}
                                      moveDirection={"horizontal"}
                                      onDelete={() =>
                                        handleOnMarkerDelete(index)
                                      }
                                      position={index}
                                      arrayLength={props.content.content.length}
                                      openConfigModal={() => {
                                        dispatch(
                                          showNestedConfigModal({
                                            nestedPosition: index,
                                            position: props.position,
                                            contentElementSetting:
                                              ceSettingById(
                                                valueFromStoreSetting(
                                                  props.content
                                                    .cfgStrContentElementSetting
                                                )
                                              ),
                                          })
                                        );
                                      }}
                                    >
                                      <PbContentRichText
                                        className={clsx(richTextClassName)}
                                        content={element.text}
                                        ceSettings={getNestedCeSettingsData(
                                          props.ceSettings?.richtext!
                                        )}
                                        position={props.position}
                                        toolbarBelow
                                        cmsFieldContent={`content[${index}].text`}
                                      />
                                    </CmsGeneralConfigFrame>
                                  </div>
                                </AnimationWrapper>
                              </div>
                            </Resizable>
                          </Draggable>
                        </React.Fragment>
                      );
                    }
                  )}
                </div>
              </div>
            </Col>
          </Row>
          {editView && (
            <div className="edit-buttons-wrapper">
              <div className="add-text-box">
                <PbButton
                  style={{
                    margin: "0 0 0 10px",
                    fontWeight: "bold",
                    height: "50px",
                  }}
                  startIcon={
                    <PbIcon
                      name="plus-light"
                      width={20}
                      height={20}
                      className="svg-fill-white"
                    />
                  }
                  onClick={() =>
                    dispatch(
                      cmsAddNestedContentElementThunk(
                        nestedElementAttributes,
                        props.position,
                        "content",
                        props.content.content.length + 1
                      )
                    )
                  }
                >
                  {tCms("add")}
                </PbButton>
              </div>
              <div className="enter-position-editmode-box">
                <PbButton
                  onlyVisibleInEditView
                  onClick={() => setPositionEditMode((prevState) => !prevState)}
                >
                  {tCms("imgWithMarkers-togglePositionEditMode", {
                    activate: positionEditMode
                      ? tCms("disable")
                      : tCms("enable"),
                  })}
                </PbButton>
              </div>
              <div>
                <PbButton
                  onlyVisibleInEditView
                  onClick={() =>
                    showResetPositionConfirmModal({
                      title: tCms("imgWithMarkers-resetPositionEditMode"),
                      content: tCms(
                        "imgWithMarkers-resetPositionEditModeConfirm"
                      ),
                      acceptBtnText: tCms("resetting"),
                      denyBtnText: tCms("cancel"),
                      xIsDeny: true,
                      icon: "triangle-exclamation-light",
                    })
                  }
                  style={{
                    margin: "0 0 0 10px",
                    fontWeight: "bold",
                    height: "50px",
                  }}
                  color="error"
                >
                  {tCms("imgWithMarkers-resetPositionEditMode")}
                </PbButton>
              </div>
            </div>
          )}
        </div>
        <PbGenericModal
          hideHeader
          className={`${IMAGE_WITH_MARKERS_CE_NAME}-text-box`}
          open={dialogOpen ? true : false}
          title={tCms("addNewElement")}
          onBackdropClick={() => toggleDialog(undefined)}
          muiDialogProps={{
            fullWidth: true,
            maxWidth: "lg",
            PaperProps: {
              style: {
                minWidth: "150px",
                width: "unset",
                backgroundColor:
                  overlayItemBackgroundColor(
                    dialogContent?.cfgStrBackgroundColor
                  ) ?? "var(--pb-white)",
              },
            },
          }}
          muiDialogContentSxProps={{ paddingRight: "50px !important" }}
        >
          <>
            <button
              className="close-text-box"
              onClick={() => toggleDialog(undefined)}
            >
              <PbIcon
                name="xmark-light"
                width={25}
                height={25}
                className="svg-fill-black"
              />
            </button>
            <PbContentRichText
              className={clsx(richTextClassName)}
              content={dialogContent?.text ?? ""}
              ceSettings={getNestedCeSettingsData(props.ceSettings?.richtext!)}
              position={props.position}
              disableEditView
            />
          </>
        </PbGenericModal>
      </ContentElementWrapper>
      <ImageWithMarkersContentElementStyleGlobal {...props} />
      <style jsx>{`
        .${`${IMAGE_WITH_MARKERS_CE_NAME}-wrapper`} {
          :global(.richtext-editor) {
            border: none !important;
          }
        }
      `}</style>
      <ImageWithMarkersContentElementStyleScoped
        scopedSelector={IMAGE_WITH_MARKERS_SCOPED_CLASS}
        {...props}
      />
    </>
  );
});
