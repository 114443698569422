import {
  lowerBreakpointDesktopPx,
  lowerBreakpointTabletPx,
  lowerBreakpointWqhdPx,
} from "@/utils/util";
import { ImageWithMarkersContentElementProps } from "./imageWithMarkersContentElement";

interface ImageWithMarkersContentElementStyleScopedProps
  extends ImageWithMarkersContentElementProps {
  scopedSelector: string;
}

/**
 * This is the scoped style from a single CE instance
 * Here you can explicitly style one CE
 *
 * @param props
 * @returns
 */
export default function ImageWithMarkersContentElementStyleScoped(
  props: ImageWithMarkersContentElementStyleScopedProps
) {
  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
        @keyframes pulse {
          0% {
            opacity: 0.5;
            transition: opacity 0.5s linear;
            height: 100%;
            width: 100%;
          }

          50% {
            opacity: 0;
            transform: scale(1.8);
            transition: opacity 0.5s linear;
          }

          100% {
            opacity: 0;
            transition: opacity 0.5s ease;
          }
        }

        .text-box {
          max-height: ${props.content.cfgImageHeight}px;
          max-width: ${props.content.cfgImageWidth}px;
          .richtext-view,
          .ql-editor {
            margin-right: ${props.ceSettings?.textMargin.right ?? 0}px;
            margin-left: ${props.ceSettings?.textMargin.left ?? 0}px;
          }
        }

        .marker {
          height: ${props.ceSettings?.markerSize.mobile}px;
          width: ${props.ceSettings?.markerSize.mobile}px;

          @media (min-width: ${lowerBreakpointTabletPx}) {
            height: ${props.ceSettings?.markerSize.tablet}px;
            width: ${props.ceSettings?.markerSize.tablet}px;
          }

          @media (min-width: ${lowerBreakpointDesktopPx}) {
            height: ${props.ceSettings?.markerSize.desktop}px;
            width: ${props.ceSettings?.markerSize.desktop}px;
          }

          @media (min-width: ${lowerBreakpointWqhdPx}) {
            height: ${props.ceSettings?.markerSize.wqhd}px;
            width: ${props.ceSettings?.markerSize.wqhd}px;
          }
          .marker-pulse {
            border-radius: 50%;
            margin: 0;
            animation: pulse 1.5s infinite;
            position: absolute;
          }
        }
      }
    `}</style>
  );
}
