import {
  IMAGE_WITH_MARKERS_CE_NAME,
  ImageWithMarkersContentElementProps,
} from "./imageWithMarkersContentElement";

export default function ImageWithMarkersContentElementStyleGlobal(
  props: ImageWithMarkersContentElementProps
) {
  return (
    <style jsx global>{`
      :global(.${IMAGE_WITH_MARKERS_CE_NAME}-wrapper) {
        &:hover .${`${IMAGE_WITH_MARKERS_CE_NAME}`} .edit-buttons-wrapper, &:focus-within .${`${IMAGE_WITH_MARKERS_CE_NAME}`} .edit-buttons-wrapper {
          align-items: center;
          align-content: center;
          display: flex;
          button {
            display: flex;
          }
          .add-text-box {
            width: auto;
            > div {
              padding: 0 !important;
              .cms-btn-add-content {
                padding: 0.6rem 1.6rem;
              }
            }
          }
          .enter-position-editmode-box {
            width: auto;
            .cms-default-button {
              min-width: 125px;
              min-height: 50px;
              margin-left: 10px;
              font-weight: bold;
            }
          }
        }

        .${`${IMAGE_WITH_MARKERS_CE_NAME}`} {
          position: relative;
          &.edit-mode {
            overflow: hidden;
          }

          .edit-buttons-wrapper {
            min-height: 50px;
            button {
              display: none;
            }
          }

          .content {
            position: relative;

            .marker-position-wrapper-free {
              position: absolute;
              // z-index: 99;
              background-color: unset;
              border: unset;
              top: calc(50% - 16px);
              left: calc(50% - 21px);
              display: none;
              // width: 42px;
              // height: 32px;

              // top: -15px;
              &.visible,
              &.edit-mode {
                display: block !important;
              }

              .marker-button {
                background-color: unset;
                border: unset;
                .marker-container {
                  margin: auto;
                  position: relative;
                  .marker {
                    padding: 7px;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    span {
                      display: none;
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      color: white;
                      text-shadow: 1px 1px 5px black;
                      font-size: 1.3rem;
                    }
                    &:hover {
                      cursor: pointer;
                    }
                  }
                }
              }
              &.edit-mode {
                .marker-button {
                  .marker {
                    span {
                      display: block;
                    }
                  }
                }
              }
            }

            .disable-overflow {
              // was a fix for the pulse animation creating a scrollbar
              // with enough distance to the image border this shouldn't be needed
              // overflow: hidden;
              // position: relative;
            }
            img {
              pointer-events: none;
            }
            .text-box {
              // min-width: 300px;
              // padding: 1rem;
              // height: fit-content !important;
              position: absolute;
              left: 50%;
              top: 50%;
              .drag-zone {
                z-index: 300;
                display: none;
                width: 70px;
                height: 70px;
                position: absolute;
                left: -50px;
                top: -50px;
                border-radius: 50%;
                border: 1px solid rgb(25, 143, 217);
                background-color: rgba(42, 48, 53, 0.8);
                box-shadow: 1px 1px 8px -2px black;
                cursor: move;
                &:hover {
                  background-color: rgba(25, 143, 217, 0.8);
                  border: 1px solid rgb(42, 48, 53);
                  span {
                    color: rgb(32, 32, 32);
                  }
                }
                span {
                  font-size: 14px;
                  font-weight: 600;
                  text-align: center;
                  color: rgb(219, 219, 219);
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
              &.edit-mode {
                .drag-zone {
                  display: block;
                }
              }
              .animation-wrapper,
              .animate__animated {
                height: 100%;
                > div {
                  height: 100%;
                }
              }
              // border: 1px solid rgb(153, 40, 40);
              .text-content {
                height: 100%;

                .richtext-editor .ql-container.ql-snow {
                  border: none !important;
                }

                .editor-container {
                  height: 100%;
                  overflow-y: auto;
                }

                &.invisible {
                  visibility: hidden;
                  display: none;
                }
                .ql-editor {
                  overflow: visible;
                  // padding: 3px 7px 0 !important;
                }
                .cms-content-richtext {
                  // padding: 2px 0 4px;
                  .ql-editor {
                    // padding: 0px;
                    overflow-x: hidden;
                  }
                  .ql-toolbar {
                    background-color: white;
                  }
                }
              }

              .marker-position-wrapper {
                position: absolute;
                z-index: 9999;
                background-color: unset;
                border: unset;
                top: -15px;
                &.visible {
                  display: block !important;
                }
                &.right {
                  right: -15px;
                }
                &.left {
                  left: -15px;
                }
              }
              .marker-container {
                margin: auto;
                position: relative;
              }

              .marker {
                height: 30px;
                width: 30px;
                border-radius: 50%;
                &:hover {
                  cursor: pointer;
                }
              }

              .marker-pulse {
                border-radius: 50%;
                margin: 0;
                animation: pulse 1.5s infinite;
              }

              .general-config-frame {
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
                .pb-richtext {
                  height: 100%;
                }
              }

              .pb-richtext {
                padding: 1px 1px !important;
              }

              .x-y {
                position: absolute;
                left: 0;
                bottom: 0;
              }
              &.edit-mode {
                .react-resizable-handle {
                  display: block;
                }
              }
              .react-resizable-handle {
                display: none;
                position: absolute;
                width: 20px;
                height: 20px;
                bottom: 4px;
                right: 4px;
                background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=");
                background-position: bottom right;
                padding: 0 3px 3px 0;
                background-repeat: no-repeat;
                background-origin: content-box;
                box-sizing: border-box;
                cursor: se-resize;
              }
            }
          }
        }
      }

      :global(.${IMAGE_WITH_MARKERS_CE_NAME}-text-box) {
        transition-duration: 0s !important;

        .MuiDialogContent-root {
          transition-duration: 0s !important;

          padding: 1.25rem 2.5rem;
        }
        .close-text-box {
          background-color: unset;
          border: unset;
          position: absolute;
          right: 5px;
          top: 10px;
          svg {
            transform: scale(1.4);
          }
        }
      }
    `}</style>
  );
}
